import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'

import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { isLocal } from '../../utils/env.utils'
import { Auth } from 'aws-amplify'

const getAccessToken = async (): Promise<string | null> => {
  try {
    const user = await Auth.currentSession()
    return user?.getIdToken()?.getJwtToken()
  } catch (error) {
    console.error(error)
  }
  return undefined
}

const httpLink = createHttpLink({
  uri: `${isLocal() ? 'http' : 'https'}://${
    process.env.NEXT_PUBLIC_SERVER_API
  }/graphql`,
})

const getAuthHeaders = async () => {
  const token = await getAccessToken()
  return token ? { Authorization: `Bearer ${token}` } : {}
}

const wsLink = process.browser
  ? new WebSocketLink({
      uri: `wss://${process.env.NEXT_PUBLIC_SERVER_API}/graphql`,
      options: {
        reconnect: true,
        lazy: true,
        inactivityTimeout: 30000,
        connectionParams: async () => {
          return { headers: await getAuthHeaders() }
        },
      },
    })
  : null

const authLink = setContext(async (_, { headers }) => {
  const token = await getAccessToken()
  const result = {
    headers: {
      ...headers,
    },
  }
  if (token) result['headers']['Authorization'] = `Bearer ${token}`
  return result
})

const link = process.browser
  ? split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      authLink.concat(httpLink)
    )
  : authLink.concat(httpLink)

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
})

export default client
