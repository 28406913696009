import {
  createContext,
  Dispatch,
  ReactElement,
  useContext,
  useState,
} from 'react'

type SearchContextType = {
  state?: any
  setHitsPlaces?: Dispatch<any>
}
export const SearchContext = createContext<SearchContextType>({})
export const SearchContextProvider = ({
  children,
}: {
  children: ReactElement
}): ReactElement => {
  const [state, setHitsPlaces] = useState([])

  return (
    <SearchContext.Provider value={{ state, setHitsPlaces }}>
      {children}
    </SearchContext.Provider>
  )
}

export const useSearch = (): SearchContextType => useContext(SearchContext)
