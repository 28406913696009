import {
  createContext,
  Dispatch,
  ReactElement,
  useContext,
  useState,
} from 'react'

type TripPlanSearchContextType = {
  search?: string
  setSearch?: Dispatch<any>
  selectedTravelElement?: string
  setSelectedTravelElement?: Dispatch<any>
}
export const TripPlanSearchContext = createContext<TripPlanSearchContextType>(
  {}
)
export const TripPlanSearchContextProvider = ({
  children,
}: {
  children: ReactElement
}): ReactElement => {
  const [search, setSearch] = useState('')
  const [selectedTravelElement, setSelectedTravelElement] = useState('')

  return (
    <TripPlanSearchContext.Provider
      value={{
        search,
        setSearch,
        selectedTravelElement,
        setSelectedTravelElement,
      }}
    >
      {children}
    </TripPlanSearchContext.Provider>
  )
}

export const useTripPlanSearch = (): TripPlanSearchContextType =>
  useContext(TripPlanSearchContext)
