import {
  createContext,
  Dispatch,
  ReactElement,
  useContext,
  useState,
} from 'react'

type SearchPlaceContextType = {
  searchPlace?: Array<any>
  setSearchPlace?: Dispatch<any>
  searchPlaces?: any
  setSearchPlaces?: Dispatch<any>
  searchResultsMap?: any
  setSearchResultsMap?: Dispatch<any>
}
export const SearchPlaceContext = createContext<SearchPlaceContextType>({})
export const SearchPlaceContextProvider = ({
  children,
}: {
  children: ReactElement
}): ReactElement => {
  const [searchResultsMap, setSearchResultsMap] = useState([])
  const [searchPlace, setSearchPlace] = useState([])
  const [searchPlaces, setSearchPlaces] = useState([])

  return (
    <SearchPlaceContext.Provider
      value={{
        searchPlace,
        setSearchPlace,
        searchPlaces,
        setSearchPlaces,
        searchResultsMap,
        setSearchResultsMap,
      }}
    >
      {children}
    </SearchPlaceContext.Provider>
  )
}

export const useSearchPlace = (): SearchPlaceContextType =>
  useContext(SearchPlaceContext)
