import Storage from '@aws-amplify/storage'

type UploadFileType = {
  rawKey: string
  contentType: string
  file: any
}

export default function useS3() {
  async function uploadFile(item: UploadFileType) {
    const { rawKey, contentType, file } = item
    const { key } = await Storage.put(rawKey, file, {
      contentType,
    })
    return key
  }

  async function getImageUrl(key: string) {
    return `${process.env.NEXT_PUBLIC_ASSETS_CLOUDFRONT}${key}`
  }

  const s3 = {
    uploadFile,
    getImageUrl,
  }

  return [s3]
}

export async function uploadFile(item: UploadFileType) {
  const { rawKey, contentType, file } = item
  const { key } = await Storage.put(rawKey, file, {
    contentType,
  })
  return key
}

export async function getImageUrl(key: string) {
  return `${process.env.NEXT_PUBLIC_ASSETS_CLOUDFRONT}${key}`
}
