import { getImageUrl } from './useS3'

export async function getProfilePhotoUrl(profileCover, profileId) {
  const url = await getImageUrl(
    `${
      profileCover?.includes('default') ? 'travatar_default_picture' : profileId
    }/${profileCover}`
  )
  return url
}
