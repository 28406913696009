import { createTheme } from '@mui/material/styles'

export const colors = {
  primary: {
    main: '#ea8942',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#b6ea42',
    contrastText: '#ffffff',
  },
  text: {
    secondary: 'rgba(255,255,255,0.6)',
    primary: '#ffffff',
  },
  componentBackground: {
    main: '#32313f',
    secondary: '#0d0c19',
    border: '#565564',
  },
}

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        h1 {
          color: grey;
        }
      `,
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '20px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: '#1D1C29',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          borderRadius: '5px',
          backgroundColor: colors.componentBackground.main,
          marginBottom: '15px',
          'label + &': {
            marginTop: '25px',
          },
          '.MuiFormHelperText-root': { margin: 0 },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '.MuiMenu-paper ': {
            borderRadius: '5px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          svg: { color: '#fff', fontSize: '18px' },
          '.Mui-disabled': {
            WebkitTextFillColor: 'rgba(203, 203, 203, 0.6)!important',
          },
          padding: '5px',
        },
        input: {
          padding: '6px 16px !important',
          fontSize: '12px',
          backgroundColor: colors.componentBackground.main,
        },
        notchedOutline: {
          borderColor: 'transparent',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: colors.componentBackground.main,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: '#2b2841',
          padding: 0,
          marginTop: '3px',
          '.MuiMenuItem-root': {
            borderBottom: '1px solid #4f4e5e',
            minHeight: 'auto!important',
          },
          '.MuiMenuItem-root:last-child': {
            borderBottom: 'none',
          },
          '.MuiMenuItem-root:hover': {
            backgroundColor: '#423e60',
          },
          '.Mui-selected': {
            backgroundColor: '#423e60',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&::before': { backgroundColor: 'transparent' },
          '& .MuiAccordionSummary-root': {
            gap: '10px',
            justifyContent: 'flex-start',
            padding: '0',
          },
          '& .MuiAccordionSummary-content': {
            flexGrow: '0',
            margin: '5px 0',
          },
          '& .MuiAccordionDetails-root': {
            padding: '0',
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          color: '#CBCBCB',
          fontSize: '14px',
          padding: '0px !important',
          borderRadius: '5px',
          '&::placeholder': {
            fontFamily: 'inherit',
          },
          '&:focus': {
            color: '#CBCBCB',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: 10,
          background: `${colors.componentBackground.main}`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          fontWeight: 'bold',
          '&.Mui-disabled': {
            color: '#fff !important',
            backgroundColor: '#BABABA !important',
          },
        },
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: 'error' },
          style: {
            color: '#EA424D',
          },
        },
        {
          props: { severity: 'success' },
          style: {
            color: '#B6EA42',
          },
        },
        {
          props: { severity: 'warning' },
          style: {
            color: '#ffc107',
          },
        },
        {
          props: { severity: 'info' },
          style: {
            color: '#03a9f4',
          },
        },
      ],
      styleOverrides: {
        root: {
          backgroundColor: '#1D1C29',
          borderRadius: '12px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.componentBackground.main,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '',
          borderRadius: '12px',
          flexShrink: 0,
          '& .MuiCardActionArea-root': {
            padding: '16px 12px',
            width: '100%',
          },
        },
      },
    },
  },

  typography: {
    fontFamily: ['Roboto Condensed', 'sans-serif'].join(','),
    button: {
      textTransform: 'capitalize',
    },
    h1: {
      fontSize: '30px',
      lineHeight: '50px',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '24px',
      lineHeight: '50px',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '20px',
      lineHeight: '50px',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '16px',
    },
    h5: {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: '500',
    },
    subtitle1: {
      fontSize: '14px',
      lineHeight: '22px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 600,
    },
    body1: {
      fontSize: '12px',
    },
    body2: {
      fontWeight: 600,
    },
  },
  palette: {
    ...colors,
  },
})

declare module '@mui/material/styles' {
  interface Palette {
    componentBackground: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    componentBackground?: PaletteOptions['primary']
  }
}

export default theme
