import { ReactElement, ReactNode } from 'react'
import Head from 'next/head'
import type { NextPage } from 'next'
import { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from '../styles/theme'
import client from '../gql/clients/apollo-client'

import '../styles/globals.css'
import { pdfjs } from 'react-pdf'
import { Worker } from '@react-pdf-viewer/core'
import Amplify from 'aws-amplify'
import awsconfig from '../../_aws-exports'
import AuthContext from '../context/Auth'
import { SearchContextProvider } from '../context/Search'
import { SearchPlaceContextProvider } from '../context/SearchPlace'
import { TripPlanSearchContextProvider } from '../context/TripPlanSearchContext'
import { InstantSearch } from 'react-instantsearch-hooks-web'
import algoliasearch from 'algoliasearch/lite'

Amplify.configure({ ...awsconfig, ssr: true })

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function MyApp({ Component, pageProps: { ...pageProps } }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page)

  const searchClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
  )

  return (
    <AuthContext>
      <SearchContextProvider>
        <TripPlanSearchContextProvider>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
          >
            <InstantSearch
              searchClient={searchClient}
              indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME}
            >
              <SearchPlaceContextProvider>
                <ApolloProvider client={client}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Head>
                      <meta
                        name="viewport"
                        content="initial-scale=1, width=device-width"
                      />
                    </Head>
                    {getLayout(<Component {...pageProps} />)}
                  </ThemeProvider>
                </ApolloProvider>
              </SearchPlaceContextProvider>
            </InstantSearch>
          </Worker>
        </TripPlanSearchContextProvider>
      </SearchContextProvider>
    </AuthContext>
  )
}

export default MyApp
